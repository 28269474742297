import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "has-deactivate-button": false,
      "title": "Edit Grade"
    }
  }, [_c("select-input", {
    key: "cropId",
    attrs: {
      "label": "Crop Code",
      "placeholder": "Select Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "source-label": _vm.genCropCodeLabel,
      "disabled": true,
      "span": 12
    }
  }), _c("text-input", {
    key: "code",
    attrs: {
      "label": "Grade Code",
      "placeholder": "Type Code",
      "disabled": true,
      "span": 12
    }
  }), _c("text-input", {
    key: "description",
    attrs: {
      "label": "Description",
      "placeholder": "Type Description",
      "max-length": 40,
      "span": 12
    }
  }), _c("text-input", {
    key: "sequence",
    attrs: {
      "label": "Sequence",
      "placeholder": "Type Sequence",
      "span": 12
    }
  }), _c("switch-input", {
    key: "warningTolerance",
    attrs: {
      "label": "Warning Tolerance",
      "span": 8
    }
  }), _c("text-input", {
    key: "warningToleranceLimit",
    attrs: {
      "label": "Warning Tolerance Limit",
      "placeholder": "Type Warning Tolerance Limit",
      "max-length": 2,
      "span": 16
    }
  }), _c("text-input", {
    key: "warningText",
    attrs: {
      "label": "Warning Text",
      "placeholder": "Type Warning Text",
      "span": 24
    }
  }), _c("switch-input", {
    key: "observedTolerance",
    attrs: {
      "label": "Observed Tolerance",
      "span": 8
    }
  }), _c("text-input", {
    key: "observedToleranceLimit",
    attrs: {
      "label": "Observed Tolerance Limit",
      "placeholder": "Type Observed Tolerance Limit",
      "max-length": 2,
      "span": 16
    }
  }), _c("switch-input", {
    key: "downloadToJag",
    attrs: {
      "label": "Jag to Scale",
      "span": 8
    }
  }), _c("text-input", {
    key: "appliedDeduction",
    attrs: {
      "label": "Applied Deduction",
      "placeholder": "Type Applied Deduction",
      "max-length": 2,
      "span": 16
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditGrades",
  methods: {
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditGrades = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-grade"
  }, [_c("resource", {
    attrs: {
      "name": "grower.grade-types",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.grades",
      "api-url": _vm.apiUrl,
      "edit": _vm.EditGrades,
      "redirect-route": "/watties-grower/grades"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditGradesTare",
  data() {
    return {
      EditGrades,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
